// TemplatesModal.js

import React, { useRef, useEffect, useState, useCallback } from 'react';
import FocusTrap from 'focus-trap-react';
import './TemplatesModal.css';
import PreviewCanvas from '../PreviewCanvas';
import useInView from '../../hooks/useInView';

const levelOptions = ['All', '1', '2', '3'];
const subjectOptions = ['All Subjects', 'Literacy', 'Maths', 'Science', 'Art', 'Communication'];

const TemplatesModal = ({
  templates,
  categories,
  searchTerm,
  onClose,
  onLoadTemplate,
  onSearchTermChange,
  setTemplates
}) => {
  const modalRef = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState('All');
  const [selectedSubject, setSelectedSubject] = useState('All Subjects');
  const [visibleCount, setVisibleCount] = useState(24);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') onClose();
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  const templateMatchesFilters = (template) => {
    const lowerSearch = searchTerm.toLowerCase();
    const matchesTitle = template.name.toLowerCase().includes(lowerSearch);
    const matchesTags = Array.isArray(template.tags)
      ? template.tags.some(tag => tag.toLowerCase().includes(lowerSearch))
      : false;
    const matchesSearch = matchesTitle || matchesTags;
    const matchesLevel =
      selectedLevel === 'All' || (template.level && template.level.split(',').includes(selectedLevel));
    const matchesSubject =
      selectedSubject === 'All Subjects' ||
      (template.subject && template.subject.split(',').map(s => s.trim()).includes(selectedSubject));
    return matchesSearch && matchesLevel && matchesSubject;
  };

  const allCategoriesWithTemplates = categories.filter(category =>
    templates.some(template =>
      template.category === category && templateMatchesFilters(template)
    )
  );

  const allFilteredTemplates = templates.filter(template =>
    (selectedCategory === null || template.category === selectedCategory) &&
    templateMatchesFilters(template)
  );

  const filteredTemplates = allFilteredTemplates.slice(0, visibleCount);

  const isNewTemplate = (createdAt) => {
    if (!createdAt) return false;
    const createdDate = new Date(createdAt);
    const now = new Date();
    const diffInDays = (now - createdDate) / (1000 * 60 * 60 * 24);
    return diffInDays <= 14;
  };

  return (
    <div className="modal-overlay" role="dialog" aria-modal="true" aria-labelledby="templates-modal-title">
      <FocusTrap>
        <div className="templates-modal" ref={modalRef}>
          <div className="templates-modal-inner">
            <div className="templates-modal-header">
              <h2 className="templates-modal-title">Templates</h2>
              <button
                onClick={onClose}
                className="templates-modal-close-btn"
                aria-label="Close Templates Modal"
              >
                ×
              </button>
            </div>

            <div className="templates-modal-controls">
              <input
                type="text"
                placeholder="Search templates..."
                value={searchTerm}
                onChange={(e) => onSearchTermChange(e.target.value)}
                aria-label="Search Templates"
              />

              <div className="level-filters">
                {levelOptions.map(level => (
                  <button
                    key={level}
                    className={`level-button ${selectedLevel === level ? 'active' : ''}`}
                    onClick={() => setSelectedLevel(level)}
                  >
                    {level === 'All' ? 'All Levels' : `Level ${level}`}
                  </button>
                ))}
              </div>

              <select
                value={selectedSubject}
                onChange={(e) => setSelectedSubject(e.target.value)}
                className="subject-dropdown"
                aria-label="Filter by subject"
              >
                {subjectOptions.map(subject => (
                  <option key={subject} value={subject}>{subject}</option>
                ))}
              </select>
            </div>

            <div className="templates-modal-content">
              <div className="templates-modal-sidebar">
                <ul>
                  <li>
                    <button
                      className={`category-button ${selectedCategory === null ? 'active' : ''}`}
                      onClick={() => setSelectedCategory(null)}
                    >
                      All Templates
                    </button>
                  </li>
                  {allCategoriesWithTemplates.map((category) => (
                    <li key={category}>
                      <button
                        className={`category-button ${selectedCategory === category ? 'active' : ''}`}
                        onClick={() => setSelectedCategory(category)}
                        aria-label={`Select category ${category.replace(/_/g, ' ')}`}
                      >
                        {category.replace(/_/g, ' ')}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="templates-modal-grid">
                {filteredTemplates.length > 0 ? (
                  filteredTemplates.map((template, index) => (
                    <LazyTemplateCard
                      key={`${template.file}-${template.category}`}
                      template={template}
                      index={index}
                      onLoadTemplate={onLoadTemplate}
                      setTemplates={setTemplates}
                      isNewTemplate={isNewTemplate}
                    />
                  ))
                ) : (
                  <p>No templates match your filters.</p>
                )}

                {/* ✅ Show More button */}
                {filteredTemplates.length < allFilteredTemplates.length && (
                  <div className="template-card show-more-card">
                    <button
                      onClick={() => setVisibleCount(visibleCount + 24)}
                      className="show-more-button"
                    >
                      Show More
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </FocusTrap>
    </div>
  );
};

const LazyTemplateCard = ({ template, index, onLoadTemplate, setTemplates, isNewTemplate }) => {
  const [ref, isInView] = useInView({ threshold: 0.1 });

  const handleLazyFetch = useCallback(async () => {
    if (!template.firstPage && isInView) {
      try {
        const templatePath = `${process.env.REACT_APP_BACKEND_BASE_URL}/${template.category}/${template.file}`;
        console.log(`🟢 Lazy loading: ${templatePath}`);
        const fileRes = await fetch(templatePath);
        const fileJson = await fileRes.json();

        setTemplates(prev =>
          prev.map(t =>
            t.file === template.file && t.category === template.category
              ? { ...t, firstPage: fileJson.pages?.[0] || null }
              : t
          )
        );
      } catch (err) {
        console.warn(`⚠️ Failed lazy load: ${template.name}`, err);
      }
    }
  }, [isInView, template, setTemplates]);

  useEffect(() => {
    handleLazyFetch();
  }, [handleLazyFetch]);

  return (
    <div
      ref={ref}
      className="template-card"
      style={{ backgroundColor: template.color || '#f0f0f0' }}
      onClick={() => onLoadTemplate(template.file, template.category)}
      aria-label={`Load template ${template.name}`}
    >
      {template.firstPage && isInView ? (
        <div className="preview-container">
          <div className="preview-padding-wrapper">
            <PreviewCanvas
              page={template.firstPage}
              maxWidth={180}
              maxHeight={280}
              className="fade-in"
            />
          </div>
        </div>
      ) : (
        <div className="template-image placeholder">Loading preview…</div>
      )}
      <div className="template-name-with-badge">
        <p className="template-name">{template.name}</p>
        {isNewTemplate(template.createdAt) && (
          <span className="new-badge">NEW</span>
        )}
      </div>
    </div>
  );
};

export default TemplatesModal;

