// SplashScreen.js

import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  faUserShield,
  faFile,
  faFolderOpen,
  faClone
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SplashScreen.css';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import LottieLoader from '../LoadingSpinner/LottieLoader';

// Images
import HeaderImage from '../../assets/Images/Header.png';
import SupportImage from '../../assets/Images/support.png';
import TutorialsImage from '../../assets/Images/tutorials.png';
import TutorialsRollImage from '../../assets/Images/tutorialsroll.png';
import SupportRollImage from '../../assets/Images/supportroll.png';
import CommunityImage from '../../assets/Images/community.png';
import CommunityRollImage from '../../assets/Images/communityroll.png';
import Sample1 from '../../assets/Images/1sample.png';
import Sample2 from '../../assets/Images/2sample.png';
import Sample3 from '../../assets/Images/3sample.png';

// Social Icons
import FacebookIcon from '../../assets/Images/icons/facebook.svg';
import DiscordIcon from '../../assets/Images/icons/discord.svg';
import YoutubeIcon from '../../assets/Images/icons/youtube.svg';

// Sounds

// Component
import TemplatesModal from '../TemplatesModal/TemplatesModal';

const SplashScreen = ({ onNew, onLoad, onTemplates }) => {
  const [showTemplatesModal, setShowTemplatesModal] = useState(false);
  const [currentTutorialsImage, setCurrentTutorialsImage] = useState(TutorialsImage);
  const [currentSupportImage, setCurrentSupportImage] = useState(SupportImage);
  const [currentCommunityImage, setCurrentCommunityImage] = useState(CommunityImage);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All Worksheets");
  const [searchTerm, setSearchTerm] = useState('');
  const [templates, setTemplates] = useState([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const BACKEND_BASE_URL = 'https://edugroove.com/edugroove_backend/templates';
  const [categories, setCategories] = useState(["All Worksheets"]);

  useEffect(() => {
    fetch('https://edugroove.com/edugroove_backend/get_categories.php')
      .then(res => res.json())
      .then(folders => {
        if (Array.isArray(folders)) {
          setCategories(["All Worksheets", ...folders]);
        }
      })
      .catch(err => console.error('Failed to fetch categories:', err));
  }, []);


  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {    

    [TutorialsRollImage, SupportRollImage, CommunityRollImage].forEach(image => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  

  const handleLoadClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.json';
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        setIsLoadingFile(true);
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const jsonContent = JSON.parse(reader.result);
            onLoad(jsonContent);
          } catch (error) {
            alert('Failed to load file. Please ensure it is a valid JSON file.');
          } finally {
            setIsLoadingFile(false);
          }
        };
        reader.readAsText(file);
      }
    };
    input.click();
  };

  const handleTemplateClick = () => {
    setShowTemplatesModal(true);
    setSelectedCategory("All Worksheets");
    setSearchTerm('');
    fetchTemplates("All Worksheets");
  };

  const handleCloseTemplatesModal = () => {
    setShowTemplatesModal(false);
    setSelectedCategory(null);
    setTemplates([]);
    setSearchTerm('');
  };

  const fetchTemplates = async (category) => {
    setIsLoadingTemplates(true);
    try {
      let allTemplates = [];
  
      if (category === "All Worksheets") {
        const fetchPromises = categories
          .filter((cat) => cat !== "All Worksheets")
          .map(async (cat) => {
            try {
              const indexUrl = `${BACKEND_BASE_URL}/${cat}/index.json`;
              console.log(`📂 Fetching index: ${indexUrl}`);
              const response = await fetch(indexUrl);
              const templatesList = await response.json();
              console.log(`📄 Templates in ${cat}:`, templatesList);
  
              const templatesWithData = await Promise.all(
                templatesList.map(async (template) => {
                  try {
                    const templatePath = `${BACKEND_BASE_URL}/${cat}/${template.file}`;
                    console.log(`🟡 Trying to fetch: ${templatePath}`);
                    const fileRes = await fetch(templatePath);
                    const fileJson = await fileRes.json();
                    console.log(`✅ Loaded template: ${template.name}`);
  
                    return {
                      ...template,
                      category: cat,
                      firstPage: fileJson.pages?.[0] || null,
                    };
                  } catch (err) {
                    console.warn(`❌ Failed to load template file: ${template.name}`, err);
                    return { ...template, category: cat, firstPage: null };
                  }
                })
              );
  
              return templatesWithData;
            } catch (err) {
              console.warn(`❌ Failed to load index for category ${cat}`, err);
              return [];
            }
          });
  
        const results = await Promise.all(fetchPromises);
        allTemplates = results.flat();
  
        // ✅ Sort all templates by createdAt (newest first)
        allTemplates.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  
        setTemplates(allTemplates);
  
      } else {
        const indexUrl = `${BACKEND_BASE_URL}/${category}/index.json`;
        console.log(`📂 Fetching index: ${indexUrl}`);
        const response = await fetch(indexUrl);
        const templatesList = await response.json();
        console.log(`📄 Templates in ${category}:`, templatesList);
  
        const templatesWithData = await Promise.all(
          templatesList.map(async (template) => {
            try {
              const templatePath = `${BACKEND_BASE_URL}/${category}/${template.file}`;
              console.log(`🟡 Trying to fetch: ${templatePath}`);
              const fileRes = await fetch(templatePath);
              const fileJson = await fileRes.json();
              console.log(`✅ Loaded template: ${template.name}`);
  
              return {
                ...template,
                category,
                firstPage: fileJson.pages?.[0] || null,
              };
            } catch (err) {
              console.warn(`❌ Failed to load template file: ${template.name}`, err);
              return { ...template, category, firstPage: null };
            }
          })
        );
  
        // ✅ Sort category templates by createdAt (newest first)
        templatesWithData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  
        setTemplates(templatesWithData);
      }
  
      setSelectedCategory(category);
    } catch (error) {
      alert(`Failed to load templates.\nError: ${error.message}`);
    } finally {
      setIsLoadingTemplates(false);
    }
  };
    

    const handleTemplateLoad = async (templateFile, category) => {
      setIsLoadingTemplate(true);
      try {
        const BACKEND_BASE_URL = 'https://edugroove.com/edugroove_backend/templates';
        const response = await fetch(`${BACKEND_BASE_URL}/${category}/${templateFile}`);
        const templateData = await response.json();
        onTemplates(templateData);
        handleCloseTemplatesModal();
      } catch (error) {
        alert(`Failed to load the selected template.\nError: ${error.message}`);
      } finally {
        setIsLoadingTemplate(false);
      }
    };
  

  const usefulLinksData = [
    {
      id: 1,
      image: Sample1,
      alt: 'EDU Groove',
      text: 'Find useful information on EDU Groove!',
      link: 'https://edugroove.com',
    },
    {
      id: 2,
      image: Sample2,
      alt: 'Diddy Bots',
      text: 'Discover the Diddy Bots on the web!',
      link: 'https://diddybots.com',
    },
    {
      id: 3,
      image: Sample3,
      alt: 'Youtube',
      text: 'Find the Diddy Bots on Youtube',
      link: 'https://www.youtube.com/@DiddyBots',
    },
  ];

  return (
    <div className="splash-screen">
      <div className="splashsidebar">
      <div className="sidebar-buttons">
        <button onClick={() => { onNew(); }} className="splashsidebar-button" aria-label="Create New Project">
          <FontAwesomeIcon icon={faFile} />
          <span>New</span>
        </button>
        {currentUser && (
        <button onClick={handleLoadClick} className="splashsidebar-button" aria-label="Load Project">
          <FontAwesomeIcon icon={faFolderOpen} />
          <span>Load</span>
        </button>
        )}
        <button onClick={handleTemplateClick} className="splashsidebar-button" aria-label="Choose Template">
          <FontAwesomeIcon icon={faClone} />
          <span>Templates</span>
        </button>
        {currentUser && !currentUser.isAdmin && currentUser.parentId === null && (
          <button onClick={() => { navigate('/user-admin'); }} className="splashsidebar-button" aria-label="Manage Sub-Users">
            <FontAwesomeIcon icon={faUserShield} />
            <span>Admin</span>
          </button>
        )}
        <div className="community-section" onMouseEnter={() => { setCurrentCommunityImage(CommunityRollImage);}} onMouseLeave={() => setCurrentCommunityImage(CommunityImage)}>
          <img src={currentCommunityImage} alt="Join Our Community" className="community-image" />
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61569095273653" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <img src={FacebookIcon} alt="Facebook" className="social-icon" />
            </a>
            <a href="https://discord.gg/pHKCACsq5h" target="_blank" rel="noopener noreferrer" aria-label="Discord">
              <img src={DiscordIcon} alt="Discord" className="social-icon" />
            </a>
            <a href="https://www.youtube.com/@EDUGrooveHub" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
              <img src={YoutubeIcon} alt="YouTube" className="social-icon" />
            </a>
          </div>
        </div>
        </div>
      </div>

      <div className="main-content">
        <div className="logo-header">
          <img src={HeaderImage} alt="Logo Header" className="logo-image" />
          <p>Groove Your Way to Better Lessons!</p>
        </div>

        <div className="links-section">
          <div className="link-box">
            <a href="https://www.youtube.com/watch?v=bMPhlx8Fe1A" target="_blank" rel="noopener noreferrer">
              <img src={currentTutorialsImage} alt="Tutorial" className="link-image" onMouseEnter={() => { setCurrentTutorialsImage(TutorialsRollImage);}} onMouseLeave={() => setCurrentTutorialsImage(TutorialsImage)} />
            </a>
          </div>
          <div className="link-box">
            <a href="https://www.youtube.com/watch?v=GtL1huin9EE" target="_blank" rel="noopener noreferrer">
              <img src={currentSupportImage} alt="Support" className="link-image" onMouseEnter={() => { setCurrentSupportImage(SupportRollImage);}} onMouseLeave={() => setCurrentSupportImage(SupportImage)} />
            </a>
          </div>
        </div>

        <h3>Useful Links</h3>
        <div className="useful-links">
          {usefulLinksData.map(link => (
            <div className="link-box" key={link.id}>
              <img src={link.image} alt={link.alt} className="link-image" />
              <p>{link.text}</p>
              <a href={link.link} target="_blank" rel="noopener noreferrer" className="visit-button">
                Visit
              </a>
            </div>
          ))}
        </div>
      </div>

      {isLoadingFile && <LottieLoader message="Loading file" />}


      {isLoadingTemplates && showTemplatesModal && <LottieLoader message="Loading templates" />}


      {isLoadingTemplate && <LottieLoader message="Loading template" />}


      {showTemplatesModal && (
        <TemplatesModal
          templates={templates}
          categories={categories}
          selectedCategory={selectedCategory}
          searchTerm={searchTerm}
          onClose={handleCloseTemplatesModal}
          onLoadTemplate={handleTemplateLoad}
          onSearchTermChange={setSearchTerm}
          onFetchTemplates={fetchTemplates}
        />
      )}
    </div>
  );
};

export default SplashScreen;
